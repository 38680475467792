import { useApi } from '~/composables/useApi';

export const useFtPaypos = () => {
  const { query } = useApi();

  const getPayPOSInfor= async (valuefromvue, signature, cart_id, store) =>{
    const myGraphqlQuery = `
      query payposinfor ($valuefromvue: String!, $signature: String, $cart_id: String, $store: String!){
        payposinfor(valuefromvue: $valuefromvue, signature: $signature, cart_id: $cart_id, store: $store){
          valuefromvue
          signature
          isset
      }
    }
      `;  
    const { data, errors } = await query(myGraphqlQuery, {valuefromvue: valuefromvue, signature: signature, cart_id: cart_id, store: store});

    return {
      data,
      errors
    };
  }
  
  return {
    getPayPOSInfor,
  }
}

export default useFtPaypos;