





















import { defineComponent,ref,useContext,onMounted, useRoute, computed } from "@nuxtjs/composition-api";
import { setItem } from "~/helpers/asyncLocalStorage";
import { merge } from "lodash-es";
import QrcodeVue from 'qrcode.vue';
import useFtPaypos from '~/modules/fortytwo/paypos/useFtPaypos';
export default defineComponent({
  name: "PayPOS",
  components:{
    QrcodeVue,
  },
  // using the empty layout.
  layout: 'empty',
  setup() {
    const { app, $config } = useContext();
    // const prefixurl = $config.storeUrl;
    // workaround for "/" at the end of the storeUrl
    const prefixurl = computed(() => {
      let defaulturl = $config.storeUrl;
      if(defaulturl.charAt(defaulturl.length - 1) == '/'){
        defaulturl = defaulturl.slice(0, -1)
      }
      return defaulturl;
    });
    // const qrText = ref(`${prefixurl.value}/paypos-redirect`);
    const qrText = ref(null);
    const qrSize = ref(0);
    const { isDesktop,isTablet,isMobile } = app.$device;
    const paypos:any = ref([]);
    const paypos_error = ref(false);
    const route = useRoute();
    const store = ref(route.value.query.store);
    // call paypos graphql for set timestamp hash and return data store in local storage.
    const { getPayPOSInfor } = useFtPaypos();
    const fetchPayPosExtendedData = async () => {
      if (store.value){
        const { data, errors } = await getPayPOSInfor("sethash", null, null, store.value);
        paypos.value = merge({}, paypos.value, data);
        if (errors){
          paypos_error.value = merge({}, paypos_error.value, errors);
        }
        else{
          qrText.value = `${prefixurl.value}/paypos-redirect?store=${store.value}&signature=${paypos.value.payposinfor[0].signature}`;
          await setItem('storedData_hh', paypos.value.payposinfor[0].signature);
        } 
      }
             
    };

    // check if desktop, tablet or mobile devices.
    if (isDesktop || isTablet){
      qrSize.value = 261;
    }else if (isMobile){
      qrSize.value = 160;
    }

    // every minutes to trigger the custom query to generate hash timestamp in M2.
    setInterval(function(){
          let d = new Date();
          let s = d.getSeconds();
          // trigger while the second is 0, 0 mean 60 seconds.
          if (s==0){
            fetchPayPosExtendedData();
          } 
    }, 1000);

    onMounted(async () => {
      await Promise.all([fetchPayPosExtendedData()]);
    });

    return {
      value: qrText,
      size: qrSize,
      background:'#f47f61',
      foreground:'#fff',
      level:'L',
      store,
      paypos_error
    };
  },
});
